<script>
  import { _ } from 'svelte-i18n'
  import { Button } from 'smelte'
  import { onMount } from 'svelte'

  import { ProgressLinear } from 'smelte'

  import MandatorySection from './MandatorySection.svelte'
  import ContactSection from './ContactSection.svelte'
  import TermsSection from './TermsSection.svelte'
  import ReviewSection from './ReviewSection.svelte'

  import { slide } from 'svelte/transition'
  import { cubicInOut } from 'svelte/easing'

  let transitionOptions = { easing: cubicInOut, duration: 800 }

  import {
    currentPage,
    handleContinue,
    applicant_name,
    applicant_type,
    applicant_join_form,
    space_preference,
    space_merged,
    space_size_min,
    space_size_max,
    space_selection,
    applicant_description,
    files,
    ares_autoload,
    invoice_ico,
    invoice_dic,
    invoice_name,
    invoice_address,
    contact_name,
    contact_surname,
    contact_email,
    contact_phone,
    confirmation,
  } from './store.js'

  const SECTION_COUNT = 4

  $: progress = ($currentPage / SECTION_COUNT) * 100
  $: activeSections = Array.from(
    { length: SECTION_COUNT },
    (x, i) => i == $currentPage,
  )

  let form

  const handleSend = () => {
    form.submit()
  }

  let filePicker

  $: {
    if (filePicker) filePicker.files = $files
  }

  $: {
    if ($space_preference === 'space_preference_size') {
      $space_selection = `${$space_size_min}-${$space_size_max}m²`
    } else if ($space_preference === 'space_preference_select') {
      $space_selection = $space_merged.join(',')
    }
  }
</script>

<style>
  .logo {
    height: 0.5em;
    display: inline;
    margin-right: 0.2em;
  }
</style>

<div class="container mx-auto md:w-4/5 lg:w-2/5">
  <div class="container mx-auto text-left">
    <h2 class="font-bold text-2xl md:text-3xl lg:text-4xl">
      {$_('application_title')}
    </h2>
  </div>
  <!-- <div class="container mx-auto">{section + 1} / {SECTION_COUNT}</div> -->

  <div class="container mx-auto w-auto inline-block">
    {#each activeSections as section, index}
      {#if section}
        <span class="material-icons">radio_button_checked</span>
      {:else}
        <span class="material-icons">radio_button_unchecked</span>
      {/if}
    {/each}
  </div>

  <!-- <ProgressLinear progress={progress ? progress : 1} /> -->

  <div>
    {#if $currentPage === 0}
      <div transition:slide={transitionOptions}>
        <MandatorySection pageIndex={0} />
      </div>
    {:else if $currentPage === 1}
      <div transition:slide={transitionOptions}>
        <ContactSection pageIndex={1} />
      </div>
    {:else if $currentPage === 2}
      <div transition:slide={transitionOptions}>
        <TermsSection pageIndex={2} />
      </div>
    {:else if $currentPage === 3}
      <div transition:slide={transitionOptions}>
        <ReviewSection />
      </div>
    {/if}
  </div>

  <div class="w-1/2 float-left">
    {#if $currentPage > 0}
      <Button on:click={() => $currentPage--} color="dark" block>
        {$_('previous')}
      </Button>
    {/if}
  </div>
  <div class="w-1/2 float-right">
    {#if $currentPage === 3}
      <Button on:click={handleSend} block>{$_('send')}</Button>
    {:else}
      <Button on:click={$handleContinue} block>{$_('next')}</Button>
    {/if}
  </div>

  <!-- Full form synchronized via binding / files change watcher -->
  <div>
    <form action="https://prihlaska.czechdesignweek.com/application_submit.php" method="POST" enctype="multipart/form-data" bind:this={form}>
      <input type="hidden" name="applicant_name" bind:value={$applicant_name} />
      <input type="hidden" name="applicant_type" value={$_($applicant_type)} />
      <input
        type="hidden"
        name="applicant_join_form"
        value={$_($applicant_join_form)} />
      <input
        type="hidden"
        name="space_selection"
        bind:value={$space_selection} />
      <input
        type="hidden"
        name="applicant_description"
        bind:value={$applicant_description} />
      <input type="hidden" name="invoice_ico" bind:value={$invoice_ico} />
      <input type="hidden" name="invoice_dic" bind:value={$invoice_dic} />
      <input type="hidden" name="invoice_name" bind:value={$invoice_name} />
      <input
        type="hidden"
        name="invoice_address"
        bind:value={$invoice_address} />
      <input type="hidden" name="contact_name" bind:value={$contact_name} />
      <input
        type="hidden"
        name="contact_surname"
        bind:value={$contact_surname} />
      <input type="hidden" name="contact_email" bind:value={$contact_email} />
      <input type="hidden" name="contact_phone" bind:value={$contact_phone} />
      <input
        bind:this={filePicker}
        id="file-hidden"
        type="file"
        name="file"
        style="display: none" />
    </form>
  </div>
</div>
