<script>
  import { _ } from 'svelte-i18n'

  export let name
  export let value
</script>

<div>
  <div class="text-xs text-gray-500">{$_(name)}</div>
  <div>{value}</div>
</div>
