<script>
  import { _ } from 'svelte-i18n'
  import InvoiceFields from './InvoiceFields.svelte'

  import SectionHeading from './SectionHeading.svelte'

  export let pageIndex
</script>

<SectionHeading>{$_('contact_fields')}</SectionHeading>
<InvoiceFields {pageIndex} />
