<script>
  import { _ } from 'svelte-i18n'
  import { onMount } from 'svelte'
  import { slide } from 'svelte/transition'
  import { cubicInOut } from 'svelte/easing'
  import Panzoom from '@panzoom/panzoom'
  import SpaceSelectorRow from './SpaceSelectorRow.svelte'
  import { space_areas, space_indexes, space_merged } from './store.js'

  let transitionOptions = { easing: cubicInOut, duration: 400 }

  onMount(() => {
    const elem = document.getElementById('map')
    const panzoom = Panzoom(elem, {
      maxScale: 5,
    })

    elem.parentElement.addEventListener('wheel', panzoom.zoomWithWheel)
  })

  export let error = ''

  let getMerged = (ar, ind) => {
    return ar.map((el, i) => el + ind[i])
  }

  let checkDuplicates = () => {
    for (let index = 0; index < $space_merged.length - 1; index++) {
      const element = $space_merged[index]
      for (let index2 = index + 1; index2 < $space_merged.length; index2++) {
        if (element === $space_merged[index2]) return true
      }
    }

    return false
  }

  $: {
    $space_merged = getMerged($space_areas, $space_indexes)
    error = checkDuplicates() ? $_('space_preference_select_error_unique') : ''
  }
</script>

{#if error}
  <div class="text-error-500 text-xs" transition:slide={transitionOptions}>
    {error}
  </div>
{:else}
  <div class="text-gray-600 text-xs" transition:slide={transitionOptions}>
    {$_('space_preference_select_hint')}
  </div>
{/if}

<div class="grid grid-cols-2">
  {#each $space_areas as area, index}
    <SpaceSelectorRow
      bind:area={$space_areas[index]}
      bind:index={$space_indexes[index]} />
  {/each}
</div>

<div style="background-color: white">
  <img id="map" src="img/map-01.svg" alt="Space selection map" />
</div>
