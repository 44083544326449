<script>
  import { Label } from 'smelte'
  export let name

  export let files
  const handleChange = (e) => {
    console.log(e)
    files = e.target.files
    console.log(files)
  }
</script>

<input
  {name}
  type="file"
  class="w-full p-3 border-gray-600 radius-none mb-6 mt-2"
  on:change={handleChange}
  bind:files />
