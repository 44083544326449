<script>
  import { _ } from 'svelte-i18n'
  import {
    applicant_name,
    applicant_type,
    applicant_join_form,
    space_selection,
    applicant_description,
    files,
    invoice_ico,
    invoice_dic,
    invoice_name,
    invoice_address,
    contact_name,
    contact_surname,
    contact_email,
    contact_phone,
  } from './store.js'

  import SectionHeading from './SectionHeading.svelte'
  import SubsectionHeading from './SubsectionHeading.svelte'
  import ReviewField from './ReviewField.svelte'

  let filename = ''
  
  if ($files) {
    filename = $files[0].name
  }
</script>

<SectionHeading>{$_('review')}</SectionHeading>

<SubsectionHeading>{$_('mandatory_fields')}</SubsectionHeading>

<ReviewField name="applicant_name" value={$applicant_name} />
<ReviewField name="applicant_type" value={$_($applicant_type)} />
<ReviewField name="applicant_join_form" value={$_($applicant_join_form)} />
<ReviewField name="space_selection" value={$_($space_selection)} />
<ReviewField name="applicant_description" value={$_($applicant_description)} />
<ReviewField name="file" value={filename} />


<SubsectionHeading>{$_('invoice_details')}</SubsectionHeading>

<ReviewField name="invoice_ico" value={$invoice_ico} />
<ReviewField name="invoice_dic" value={$invoice_dic} />
<ReviewField name="invoice_name" value={$invoice_name} />
<ReviewField name="invoice_address" value={$invoice_address} />

<SubsectionHeading>{$_('contact_details')}</SubsectionHeading>

<ReviewField name="contact_name" value={$contact_name} />
<ReviewField name="contact_surname" value={$contact_surname} />
<ReviewField name="contact_email" value={$contact_email} />
<ReviewField name="contact_phone" value={$contact_phone} />

<div class="mb-8"></div>
