<script>
  import { _ } from 'svelte-i18n'
  import { Select } from 'smelte'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  export let value
  export let name
  export let items

  let label = $_(name)
  let items_translations = items.map((item) => {
    return {
      value: item,
      text: $_(item),
    }
  })
</script>

<Select
  append
  inputClasses={(i) => i.replace('rounded-t', 'rounded-none')}
  {name}
  bind:value
  {label}
  bind:items={items_translations}
  on:change={({ detail }) => {
    dispatch('change', { name: name, value: detail })
  }}
  {...$$restProps} />
