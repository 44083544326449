import { addMessages, init, getLocaleFromQueryString } from "svelte-i18n";

import en from "./en.json";
import cs from "./cs.json";

addMessages("cs", cs);
addMessages("en", en);

init({
  fallbackLocale: "cs",
  initialLocale: getLocaleFromQueryString('lang'),
});
