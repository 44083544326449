<script>
  import { _ } from 'svelte-i18n'
  import TextField from './TextField.svelte'
  import { Switch, ProgressLinear } from 'smelte'
  import { slide } from 'svelte/transition'
  import SubsectionHeading from './SubsectionHeading.svelte'

  import {
    handleContinue,
    invoice_ico,
    invoice_dic,
    invoice_name,
    invoice_address,
    contact_name,
    contact_surname,
    contact_email,
    contact_phone,
    currentPage,
    applicant_type,
  } from './store.js'

  let ares_autoload = true
  let ares_loading = false

  let ares_error = null
  import { createForm } from 'svelte-forms-lib'

  export let pageIndex

  import { string, object, mixed } from 'yup'

  let form, errors, state, handleChange, handleSubmit
  $: {
    console.log($applicant_type);
    const invoice_ico_field =
      $applicant_type == "applicant_type_student"
        ? string()
            .notRequired()
            .length(8, $_("invoice_ico_length"))
            .nullable()
            .transform((value) => (!!value ? value : null))
        : string()
            .length(8, $_("invoice_ico_length"))
            .required($_("invoice_ico_required"));

    ({ form, errors, state, handleChange, handleSubmit } = createForm({
      initialValues: {
        invoice_ico: $invoice_ico,
        invoice_dic: $invoice_dic,
        invoice_name: $invoice_name,
        invoice_address: $invoice_address,
        contact_name: $contact_name,
        contact_surname: $contact_surname,
        contact_email: $contact_email,
        contact_phone: $contact_phone,
      },
      validationSchema: object().shape({
        invoice_ico: invoice_ico_field,
        invoice_name: string().required($_('invoice_name_required')),
        invoice_address: string().required($_('invoice_address_required')),
        contact_name: string().required($_('contact_name_required')),
        contact_surname: string().required($_('contact_surname_required')),
        contact_email: string().email().required($_('contact_email_required')),
        contact_phone: string().required($_('contact_phone_required')),
      }),
      onSubmit: (values) => {
        $currentPage = pageIndex + 1
      },
    }))
  }

  let handleIcoChange = (e) => {
    console.log(+e.target.value)

    ares_loading = true
    console.log(`https://czechdesignweek.com/ares.php?ico=${+e.target.value}`)
    fetch(`https://czechdesignweek.com/ares.php?ico=${+e.target.value}`)
      .then((res) => {
        return res.json()
      })
      .then((company) => {
        if (company.status !== 'ok') {
          throw new Error($_('invoice_ares_notfound'))
        }

        let name, address, dic
        ;({ name, address, dic } = company)
        $form.invoice_name = name
        $form.invoice_address = address
        $form.invoice_dic = dic

        ares_error = ''
      })
      .catch((error) => (ares_error = error.message))
      .finally(() => (ares_loading = false))

    handleChange(e)
  }

  $: {
    if ($currentPage == pageIndex) {
      $handleContinue = () => {
        handleSubmit()
      }
    }
  }

  $: {
    invoice_ico.set($form.invoice_ico)
  }
  $: {
    invoice_dic.set($form.invoice_dic)
  }
  $: {
    invoice_name.set($form.invoice_name)
  }
  $: {
    invoice_address.set($form.invoice_address)
  }
  $: {
    contact_name.set($form.contact_name)
  }
  $: {
    contact_surname.set($form.contact_surname)
  }
  $: {
    contact_email.set($form.contact_email)
  }
  $: {
    contact_phone.set($form.contact_phone)
  }

  let ico_error
  $: {
    let ico_errors = []

    if (ares_error) ico_errors.push(ares_error)
    if ($errors.invoice_ico) ico_errors.push($errors.invoice_ico)

    ico_error = ico_errors.join(', ')
  }
</script>

<SubsectionHeading>{$_('invoice_details')}</SubsectionHeading>
<Switch bind:value={ares_autoload} label={$_('invoice_ares_autofill')} />

<form on:submit={handleSubmit}>
  <TextField
    name="invoice_ico"
    type="number"
    error={ico_error}
    on:change={handleIcoChange}
    bind:value={$form.invoice_ico} />
  {#if ares_loading}
    <div transition:slide>
      <ProgressLinear />
    </div>
  {/if}
  <TextField
    name="invoice_dic"
    bind:value={$form.invoice_dic}
    error={$errors.invoice_dic} />
  <TextField
    name="invoice_name"
    bind:value={$form.invoice_name}
    error={$errors.invoice_name} />
  <TextField
    name="invoice_address"
    bind:value={$form.invoice_address}
    error={$errors.invoice_address} />

  <SubsectionHeading>{$_('contact_details')}</SubsectionHeading>

  <TextField
    name="contact_name"
    bind:value={$form.contact_name}
    error={$errors.contact_name} />
  <TextField
    name="contact_surname"
    bind:value={$form.contact_surname}
    error={$errors.contact_surname} />
  <TextField
    name="contact_email"
    bind:value={$form.contact_email}
    error={$errors.contact_email} />
  <TextField
    name="contact_phone"
    bind:value={$form.contact_phone}
    error={$errors.contact_phone} />
</form>
