<script>
  import Select from './Select.svelte'
  import { onMount } from 'svelte'
  import Panzoom from '@panzoom/panzoom'
  import TextField from './TextField.svelte'

  const areaCounts = { A: 7, B: 20, C: 28 }
  export let area = 'A'
  export let index = 1

  $: areaCount = areaCounts[area]

  $: spaceNumbers = Array.from(
    { length: areaCounts[area] },
    (x, i) => i + 1,
  )

  let handleIndexChange = (e) => {
    index = Math.min(
      areaCount,
      Math.max(1, e.target.value),
    )
  }

  $: index = Math.min(
    areaCounts[area],
    Math.max(1, index),
  )
</script>
<Select
  name="space_letter"
  bind:value={area}
  items={['A', 'B', 'C']} />
<TextField
  name="space_index"
  type="number"
  max="28"
  min="1"
  hint={area +' 1-' + areaCount}
  error=''
  on:change={handleIndexChange}
  bind:value={index} />
