<script>
  import { _ } from 'svelte-i18n'
  import { TextField } from 'smelte'

  export let name
  export let value
  export let error

  let label = $_(name)
  let hint = $_(name + '_hint')

  if (hint === name + '_hint' || error) {
    hint = ''
  }
</script>

<TextField
  inputClasses={(i) => i.replace('rounded-t', 'rounded-none')}
  {name}
  {label}
  {hint}
  {error}
  bind:value
  persistentHint
  on:change
  {...$$restProps} />
