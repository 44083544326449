<script>
  import { Slider } from 'smelte'
  import { space_size_min, space_size_max } from './store.js'

  $: {
    if ($space_size_min > $space_size_max) {
      $space_size_max = $space_size_min
    }
  }

  $: {
    if ($space_size_max > $space_size_max) {
      $space_size_max = $space_size_min
    }
  }
</script>

<Slider label="od" min="4" step="1" max="9" bind:value={$space_size_min} />
<Slider label="do" min="4" step="1" max="9" bind:value={$space_size_max} />
<div class="text-center text-3xl">
  {$space_size_min}
  {#if $space_size_min !== $space_size_max}- {$space_size_max}{/if}
  m²
</div>
