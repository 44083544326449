<script>
  import { _, locale } from 'svelte-i18n'
  import { Checkbox, ProgressLinear } from 'smelte'
  import marked from 'marked'

  import SectionHeading from './SectionHeading.svelte'

  let terms = fetch(`data/${$_('terms_file')}.md`)
    .then((response) => {
      return response.text()
    })
    .then((text) => {
      return marked(text)
    })

  import { createForm } from 'svelte-forms-lib'

  let files

  import { handleContinue, confirmation, currentPage } from './store.js'

  export let pageIndex

  import { boolean, object } from 'yup'

  const { form, errors, state, handleChange, handleSubmit } = createForm({
    initialValues: {
      confirmation: $confirmation,
    },
    validationSchema: object().shape({
      confirmation: boolean().oneOf([true], $_('confirmation_required')),
    }),
    onSubmit: (values) => {
      $currentPage = pageIndex + 1
    },
  })

  $: {
    if ($currentPage == pageIndex) {
      $handleContinue = () => {
        handleSubmit()
      }
    }
  }

  $: {
    confirmation.set($form.confirmation)
  }
</script>

<style>
  .terms :global(ul) {
    list-style-type: disc;
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .terms :global(ol) {
    list-style-type: decimal;
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  .terms :global(ul ul),
  .terms :global(ol ul) {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }
  .terms :global(ol ol),
  .terms :global(ul ol) {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }

  .terms :global(a) {
    color: #2196f3;
  }
</style>

<SectionHeading>{$_('terms')}</SectionHeading>

<div class="terms">
  {#await terms}
    <ProgressLinear />
  {:then data}
    {@html data}
  {/await}
</div>

<hr />

<form on:submit={handleSubmit}>
  <Checkbox
    name="confirmation"
    bind:checked={$form.confirmation}
    label={$_('confirmation')}
    on:change={handleChange} />
  {#if $errors.confirmation}
    <div class="text-error-500">{$errors.confirmation}</div>
  {/if}
</form>
