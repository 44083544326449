<script>
  import { onMount, onDestroy } from 'svelte'
  import { _ } from 'svelte-i18n'
  import TextField from './TextField.svelte'
  import Select from './Select.svelte'
  import SizeSelector from './SizeSelector.svelte'
  import SpaceSelector from './SpaceSelector.svelte'
  import FileUpload from './FileUpload.svelte'
  import { slide } from 'svelte/transition'
  import { DataTable } from "smelte";

  import SectionHeading from './SectionHeading.svelte'

  //import { form, errors, state, handleChange, handleSubmit } from './form.js'

  let applicant_type_items = [
    'applicant_type_store',
    'applicant_type_manufacturer',
    'applicant_type_studio',
    'applicant_type_institution',
    'applicant_type_student',
    'applicant_type_school',
    'applicant_type_food',
  ]

  let applicant_join_form_items = [
    'applicant_join_form_czdw',
    'applicant_join_form_exterior',
    'applicant_join_form_city',
    'applicant_join_form_fashion_show',
    'applicant_join_form_gastro_zone',
    'applicant_join_form_new_generation',
  ]

  let space_preference_items = [
    'space_preference_size',
    'space_preference_select',
  ]

  // Space size

  // Form Logic
  import { createForm } from 'svelte-forms-lib'

  let filePicker

  import {
    handleContinue,
    currentPage,
    applicant_name,
    applicant_type,
    applicant_join_form,
    space_preference,
    applicant_description,
    files,
  } from './store.js'

  export let pageIndex

  import { string, object, mixed } from 'yup'

  const FILE_SIZE = 1024 * 1024 * 15 // 15MB
  const SUPPORTED_FORMATS = ['application/pdf']

  const { form, errors, state, handleChange, handleSubmit } = createForm({
    initialValues: {
      applicant_name: $applicant_name,
      applicant_type: $applicant_type,
      applicant_join_form: $applicant_join_form,
      space_preference: $space_preference,
      file: '',
      applicant_description: $applicant_description,
    },
    validationSchema: object().shape({
      applicant_name: string().required($_('applicant_name_required')),
      applicant_type: string().required($_('applicant_type_required')),
      applicant_join_form: string().required(
        $_('applicant_join_form_required'),
      ),
      space_preference: string().required($_('space_preference_required')),
      applicant_description: string()
        .required($_('applicant_description_required'))
        .min(50, $_('applicant_description_too_short'))
        .max(2000, $_('applicant_description_too_long')),
      file: mixed()
        .required($_('file_required'))
        .test('fileSize', $_('file_too_large'), (value) => {
          console.log(value)
          return value.size <= FILE_SIZE
        })
        .test('fileType', $_('file_unsupported'), (value) =>
          SUPPORTED_FORMATS.includes(value.type),
        )
        .test('name', $_('file_required'), (value) => value),
    }),
    onSubmit: (values) => {
      $currentPage = pageIndex + 1
      $errors.file = ''
    },
  })

  const handleFileChange = (e) => {
    let file = e.target.files[0]
    $files = e.target.files
    console.log(file)
    $form.file = file
    // handleChange(e)
  }

  let space_error = ''

  $: {
    if ($currentPage == pageIndex) {
      // if (filePicker) {
      //   filePicker.files = $files
      //   $form.file = filePicker.files[0]
      // }

      $handleContinue = () => {
        for (const key in $errors) {
          $errors[key] = ''
        }

        if ($form.space_preference === 'space_preference_size' || !space_error) handleSubmit()
      }
    }
  }

  const clearErrorAndHandleChange = (e) => {
    console.log(e)
    $errors[e.target.name] = ''
    handleChange(e)
  }

  const clearErrorAndHandleChangeSelect = (e) => {
    console.log(e)
    $errors[e.detail.name] = ''
    handleChange({ target: { name: e.detail.name, value: e.detail.value } })
  }

  $: {
    $applicant_name = $form.applicant_name
  }
  $: {
    $applicant_type = $form.applicant_type
  }
  $: {
    $applicant_join_form = $form.applicant_join_form
  }
  $: {
    $space_preference = $form.space_preference
  }
  $: {
    $applicant_description = $form.applicant_description
  }

  $: description_length = $form.applicant_description.length
  $: description_valid = description_length >= 50 && description_length <= 2000
</script>

<style>
  .price-table {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .price-table td, .price-table th {
    padding: 10px;
    text-align: left;
  }

  .price-table__note {
    text-align: center;
    margin-bottom: 1em;
  }
</style>

<SectionHeading>{$_('mandatory_fields')}</SectionHeading>

<form on:submit={handleSubmit}>
  <TextField
    name="applicant_name"
    bind:value={$form.applicant_name}
    error={$errors.applicant_name}
    hint={$errors.applicant_name ? '' : $_('applicant_name_hint')}
    on:change={clearErrorAndHandleChange} />
  <Select
    name="applicant_type"
    items={applicant_type_items}
    bind:value={$form.applicant_type}
    error={$errors.applicant_type}
    on:change={clearErrorAndHandleChangeSelect} />
  <Select
    name="applicant_join_form"
    items={applicant_join_form_items}
    error={$errors.applicant_join_form}
    bind:value={$form.applicant_join_form}
    on:change={clearErrorAndHandleChangeSelect} />

  <h6>{$_('space_size')}</h6>
  {#if $form.space_preference === 'space_preference_size'}
    <div transition:slide>
      <SizeSelector />
    </div>
  {:else if $form.space_preference === 'space_preference_select'}
    <div transition:slide>
      <SpaceSelector bind:error={space_error} />
    </div>
  {/if}
  <div>
    <table class="price-table">
      <thead>
        <tr>
          <th class="md:w-40">
            {$_('space_pricelist_header_size')}
          </th>
          <th class="md:w-40">
            {$_('space_pricelist_header_base_price')}
          </th>
          <th class="md:w-40">
            {$_('space_pricelist_header_student_price')}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            4 m²
          </td>
          <td>
            10&nbsp;000 CZK
          </td>
          <td>
            5&nbsp;900CZK
          </td>
        </tr>
        <tr>
          <td>
            5,8 m²
          </td>
          <td>
            13&nbsp;000 CZK
          </td>
          <td>
            7&nbsp;900 CZK
          </td>
        </tr>
        <tr>
          <td>
            8,28 m²
          </td>
          <td>
            25&nbsp;000 CZK
          </td>
          <td>
            10&nbsp;000 CZK
          </td>
        </tr>
      </tbody>
    </table>
    <p class="price-table__note">
      {$_('space_pricelist_note')}
    </p>
    <p class="price-table__note">
      {$_('space_pricelist_note_category_basic')}
    </p>
    <p class="price-table__note">
      {$_('space_pricelist_note_category_student')}
    </p>
  </div>
  <TextField
    name="applicant_description"
    bind:value={$form.applicant_description}
    error={$errors.applicant_description}
    hint={$errors.applicant_description ? '' : $_('applicant_description_hint')}
    textarea
    maxlength="2000"
    on:change={clearErrorAndHandleChange} />
  <div
    class:text-gray-600={description_valid}
    class:text-error-500={!description_valid}
    class="text-right">
    {description_length}/2000
  </div>
  <!-- <FileUpload name="file" bind:files on:change={clearErrorAndHandleChange} /> -->
  <div class="text-gray-500 text-xs">{$_('file_hint')}</div>
  <input
    name="file"
    type="file"
    class:border-gray-600={!$errors.file}
    class:border-error-500={$errors.file}
    class="w-full p-3 radius-none mb-6 mt-2"
    on:change={handleFileChange}
    accept="application/pdf"
    bind:this={filePicker} />
  {#if $errors.file}
    <div class="text-xs py-1 pl-4 bottom-1 left-0 text-error-500 ">
      {$errors.file}
    </div>
  {/if}
</form>
