import { writable } from 'svelte/store'

const currentPage = writable(0)
const handleContinue = writable(() => {})

const applicant_name = writable('')
const applicant_type = writable('')
const applicant_join_form = writable('')
const space_preference = writable('space_preference_size')

const space_areas = writable(['A', 'A', 'A'])
const space_indexes = writable([1, 2, 3])
const space_merged = writable(['A1', 'A2', 'A3'])

const space_size_min = writable(4)
const space_size_max = writable(9)

const space_selection = writable('')

const applicant_description = writable('')
const files = writable()

const ares_autoload = writable(false)

const invoice_ico = writable('')
const invoice_dic = writable('')
const invoice_name = writable('')
const invoice_address = writable('')

const contact_name = writable('')
const contact_surname = writable('')
const contact_email = writable('')
const contact_phone = writable('')

const confirmation = writable(false)

export {
  currentPage,
  handleContinue,
  applicant_name,
  applicant_type,
  applicant_join_form,
  space_preference,
  space_areas,
  space_indexes,
  space_merged,
  space_size_min,
  space_size_max,
  space_selection,
  applicant_description,
  files,
  ares_autoload,
  invoice_ico,
  invoice_dic,
  invoice_name,
  invoice_address,
  contact_name,
  contact_surname,
  contact_email,
  contact_phone,
  confirmation,
}
